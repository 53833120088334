/* header mobile */
svg {
    cursor: pointer;
}

.onlyDesktop {
    display: none;
}

.premenu {
    background-color: #0D4389;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.premenu h1 {
    color: white;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
}

.menufixo {
    position: fixed;
    z-index: 2;
    width: 100%;
    top: 0;

}

/* espaço para corrigir o menu fixo com position fixed */
.spacer {
    height: 110px;
    width: 100%;
}

.spacerD {
    display: none;
}

.headerMobile {
    height: 80px;
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ddddddff;
}

.headerMobile1 {
    display: flex;
    align-items: center;
    margin: 0px 20px;
    justify-content: center;
    text-decoration: none;

}

.headerMobile1 img {
    width: 25%;
    height: 25%;
    border-radius: 50%;
    cursor: pointer;
}

.headerMobile1 h1 {
    color: #0D4389;
    cursor: pointer;
    font-size: 20px;
    font-family: Poppins;
    line-height: 1;
    font-weight: 400;
    margin-left: 5px;

}

.headerMobile1 svg {

    color: #0D4389;
    width: 20px;
    height: 30px;

}

.headerMobile1 a,
.headerDesktop1 a {
    position: relative;
}

.headerMobile1 span,
.count {
    position: absolute;
}






/* menu hamburguer */
.menuHamburguer {
    width: 0;
    overflow: hidden;
    transition: .2s;
    top: 0;
    height: 100%;
    position: fixed;
    background-color: #F3F3F3;
    z-index: 99;

}

.active {
    overflow: auto;
    width: 100%;
    transition: .4s;
}


/* categories */
.log {
    background-color: white;


}

.log1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-bottom: 4px solid #0D4389;
    padding: 20px 0px;
}

.log1 h1 {
    text-align: center;
    font-family: Poppins;
    color: #231e1fff;
    font-weight: 700;
    font-size: 18px;

}

.log1 svg {
    margin-left: 10px;
    width: 20px;
    height: 20px;
}

.log2 {
    height: 50px;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #ddddddff;

}

.log2 div {
    width: 1px;
    height: 100%;
    background-color: #ddddddff;
}

.log2 a {
    color: #7f7f7fff;
    margin-left: 15px;
    font-size: 13px;
    text-decoration: none;
    font-family: Poppins;
    font-weight: 500;
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}

.log2 .log2 svg {
    margin-right: 5px;
}

.nav2 {
    background-color: #f3efedff;
}

.nav2 a {
    text-decoration: none;
    padding: 12px 0px;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #ddddddff;
}

.nav2 a span {
    text-transform: uppercase;
    color: #666666;
    font-family: Poppins;
    font-size: 14px;
    margin-left: 25px;
}





/* subcategories */
.subcat a {
    font-size: 20px;
    margin-left: 30px;
    margin-top: 10px;
    display: block;
    color: black;

}



@media screen and (min-width:992px) and (max-width: 4000px) {
    .headerMobile {
        display: none;
    }

    .onlyDesktop {
        display: block;
        position: fixed;
        z-index: 2;
        width: 100%;
        top: 0;
        border-bottom: 1px solid #ddddddff;
    }


    .menufixo {
        display: none;
    }

    .premenuD {
        background-color: #0D4389;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .premenuD h1 {
        color: white;
        font-family: Poppins;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
    }

    .spacerD {
        display: block;
        height: 120px;
        width: 100%;
    }

    .headerDesktop {
        display: flex;
        background-color: white;
        align-items: center;
        justify-content: space-around;
        height: 80px;
    }

    .spacer {
        display: none;
    }

    .headerDesktop1 {
        display: flex;
        align-items: center;
        text-decoration: none;

    }

    .headerDesktop1 a {
        color: #0D4389;
        font-size: 15px;
        text-decoration: none;
        font-family: Poppins;
        font-weight: 600;
    }

    .headerDesktop1 h1 {
        color: #0D4389;
        cursor: pointer;
        font-size: 25px;
        font-family: Poppins;
        line-height: 1;
        font-weight: 400;
        margin-left: 5px;
    }

    .headerDesktop1 img {
        width: 25%;
        height: 25%;
        border-radius: 50%;
        cursor: pointer;
    }


    .headerDesktop1 svg {
        width: 20px;
        height: 30px;
        margin-left: 10px;
        color: #0D4389;
    }


    .drop span {
        color: #0D4389;
        cursor: pointer;
        font-size: 23px;
        font-family: Poppins;
        font-weight: 600;
        margin-left: 60px;
    }

    .drop a {
        text-decoration: none;
    }

    .menubottom1 {
        background-color: #0D4389;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .menubottom2 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .menubottom2 h1 {
        color: white;
        font-family: Poppins;
        font-weight: 500;
        font-size: 40px;
    }

    .menubottom2 a {
        font-size: 20px;
        margin-top: 10px;
        display: block;
        color: white;
        text-decoration: none;
        font-size: 20px;
        font-weight: 300;
    }

    .nav2d {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 90%;
        padding: 20px;
    }

    .nav2d a {
        text-decoration: none;
        color: white;
        font-family: Poppins;
        font-size: 20px;
        padding: 0px 10px;
        margin: 20px 0px;
        border-left: 1px solid #f4f4f4;

    }
}