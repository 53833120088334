.compra {
  margin-top: 50px;
  width: 100%;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imagem_principal {
  display: flex;
  width: 100%;
  justify-content: center;

}

.imagem_principal img {
  width: 95%;
  cursor: pointer;
  height: 500px;
  object-fit: contain;
}

.compra1 {
  display: flex;
  width: 90%;
}

.compra1 img {
  width: 28%;
  display: flex;
  margin-top: 20px;
  cursor: pointer;
}

.compra2 {
  width: 95%;
}

.compra2 h1 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  margin: 30px 0px;
  color: #0d4389;
  text-transform: lowercase;
}

.compra2 a {
  color: #1b3768;
  font-size: 12px;
  font-family: Poppins;
  text-decoration: underline;
  cursor: pointer;
}

.compra3 {
  width: 95%;
  align-items: center;
  margin-top: 30px;
}

.compra3 h2 {
  font-family: Poppins;
  font-size: 20px;
  color: #0d4389;
  margin: 0;
  font-weight: 700;
}

.compra3 span {
  color: white;
  text-decoration: none;
  background-color: #07aa07;
  display: inline-block;
  font-size: 10px;
  border-radius: 5px;
  padding: 3px 8px;
  font-weight: 600;
  font-family: Poppins;
}

.compra3 h3 {
  font-family: Poppins;
  color: #666666;
  font-weight: 400;
  font-size: 20px;
  text-decoration: line-through;
  margin: 0;
  margin-right: 10px;
  display: inline-block;
}

.compra4 {
  width: 95%;
  margin-top: 30px;
}

.compra4 h1 {
  font-size: 15px;
  font-family: Poppins;
}

.compra4 select {
  font-family: Poppins;
  width: 70px;
  text-transform: uppercase;
}

.compra4 option {
  text-transform: uppercase;
}

.compra5 {
  width: 95%;
  margin-top: 30px;
}

.compra5 button {
  border: 0;
  width: 100%;
  padding: 10px 20px;
  background-color: #0d4389;
  color: white;
  font-size: 13px;
  font-family: Poppins;
  text-decoration: none;
  border-radius: 30px;
  cursor: pointer;
  border-style: solid;
  border-width: 0 0 6px;
  border-color: #092f61;
  font-weight: 600;
}

button.compra51 {
  margin-top: 15px;
  border: 0;
  width: 100%;
  padding: 10px 20px;
  background-color: #018bd6;
  color: white;
  font-family: Poppins;
  text-decoration: none;
  border-radius: 30px;
  cursor: pointer;
  border-style: solid;
  border-width: 0 0 6px;
  border-color: #0d73aa;
  font-weight: 500;
}

.compra6 {
  width: 95%;
  margin-top: 50px;
}

.compra6b {
  font-family: Poppins;
  margin-top: 10px;
  font-size: 14px;
  color: #226a7c;
}

.compra6b:first-child {
  color: #4a4a4a;
}

.compra6b svg {
  margin-right: 10px;
}

.compra7 {
  width: 95%;
}

.compra7 img {
  width: 70%;
  margin-top: 50px;
}

.compra8 {
  width: 95%;
  margin-top: 50px;
  margin-bottom: 20px;
}

.compra8 h1 {
  font-size: 22px;
  color: #1b3768;
  font-family: Poppins;
  font-weight: 500;
}

.compra8 h2 {
  color: #515151;
  font-size: 18px;
  font-family: Poppins;
  margin-top: 20px;
}

.compra8 p {
  color: #4b4f58;
  margin-top: 15px;
  font-family: Poppins;
  line-height: 1.6;
  font-size: 15px;
  word-wrap: break-word;
}

.compra_desktop {
  display: none;
}

.recomendados {
  border-top: 1px #777777ff dashed;
  width: 100%;
  background-color: #faf8f7;
  padding: 30px 0px;
}

.card {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #faf8f7;
  width: 100%;
  padding: 0px 0px 50px;
}

.recomendados h1 {
  width: 100%;
  font-size: 22px;
  font-family: Poppins;
  text-align: center;
  color: #0d4389;
  font-weight: 500;
}

.placeimg {
  width: 95%;
  height: 500px;
  object-fit: contain;
}

@media screen and (min-width: 992px) and (max-width: 4000px) {
  .compra {
    display: none;
  }

  .desktop1 {
    width: 75%;
  }

  .compra2 h1 {
    font-size: 26px;
  }

  .compra3 h2 {
    font-size: 26px;
  }

  .compra_desktop {
    display: block;
    width: 100%;
    margin-top: 50px;
  }


  .compra8 {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 50px;
  }

  .compra5 {
    width: 70%;
  }

  .desktop {
    display: flex;
    width: 100%;
  }

  .recomendados h1 {
    font-size: 28px;
  }
}