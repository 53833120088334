.vazio {
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 100px;
    width: 75%;
}

.vazio h1 {
    font-size: 20px;
    color: #282828;
    font-family: Poppins;
    font-weight: 500;
}

.vazio p {
    font-size: 16px;
    color: #6f6f6f;
    font-family: Poppins;
    margin-top: 20px;
    font-weight: 400;
}

.vazio button {
    background-color: #0D4389;
    height: 44px;
    width: 100%;
    margin-top: 30px;
    font-size: 16px;
    color: white;
    font-family: Poppins;
    border: 0;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
}

.vazio svg {
    width: 100px;
    height: 100px;
    color: #868686;
}

.carrinho {
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 100px;
    width: 85%;
}

.carrinho h2 {
    font-family: Poppins;
    font-size: 24px;
    color: #4a4a4a;
}

.carrinho1 h1 {
    font-family: Poppins;
    font-weight: 400;
    font-size: 20px;
}

.carrinho2 {
    display: flex;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    border-radius: 10px;
}

.carrinho3 {

    margin: 30px 10px;
}

.carrinho3 h1 {
    color: #404040;
    font-family: Poppins;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 600;
    text-transform: lowercase;
}

.carrinho3 h2 {
    color: #6f6f6f;
    font-family: Poppins;
    font-size: 12px;
    margin-top: 10px;
    font-weight: 500;
}

.carrinho3 h3 {
    font-size: 16px;
    color: #333333;
    margin-top: 10px;
    font-family: Poppins;
    font-weight: 700;
}

.carrinho3 h4 {
    font-size: 14px;
    color: #333333;
    margin-top: 10px;
    font-family: Poppins;
    font-weight: 700;
}

.carrinho3 h4 span {
    text-transform: uppercase;
}

.carrinho3 p {
    color: #868686;
    font-size: 12px;
    font-family: Poppins;
    margin-top: 20px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
}

.carrinho3 img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    display: flex;
    border-radius: 5px;
}

.carrinho3 svg {
    cursor: pointer;
    height: 20px;
    width: 40px;
}

.carrinho4 {
    width: 100%;
    margin-top: 20px;
    background-color: #f8f8f8;
    height: 100%;
    padding-bottom: 50px;
    border-radius: 10px;
    padding-top: 5px;
}

.carrinho41 {
    width: 90%;
    margin: 0 auto;
}

.carrinho4a h1 {
    font-family: Poppins;
    font-size: 22px;
    color: #333333;
    margin-top: 30px;
    font-weight: 600;
}

.carrinho4b {
    display: flex;
    align-items: center;
}

.carrinho4b h2 {
    margin-top: 30px;
    font-size: 14px;
    font-family: Poppins;
    color: #666666;
    font-weight: 400;
    width: 100%;
}

.carrinho4b h3 {
    margin-top: 15px;
    font-size: 14px;
    font-family: Poppins;
    color: #333333;
    font-weight: 700;
    width: 100%;
}

.carrinho4b h3:last-child {
    margin-top: 15px;
    font-size: 18px;
    font-family: Poppins;
    color: #333333;
    font-weight: 700;
    width: 100%;
}

.carrinho4 button {
    width: 100%;
    margin-top: 30px;
    font-weight: 600;
    cursor: pointer;
    background-color: #0D4389;
    color: white;
    border-radius: 10px;
    border: 0;
    height: 50px;
    font-size: 18px;
    font-family: Poppins;
}

.linha {
    border-bottom: 1px solid #cccccc;
    margin-top: 20px;
}

.calcFrete {
    margin-top: 80px;
}

.calcFrete h2 {
    margin-bottom: 10px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    color: #404040;
}

.calcFrete input {
    padding: 10px;
    border-radius: 5px;
    border: #0D4389 1px solid;
    font-family: Poppins;
    outline: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 40%;

}


.calcButton {
    padding: 10px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: #0D4389 1px solid;
    background-color: #0D4389;
    color: white;
    font-family: Poppins;
    cursor: pointer;
}

@media screen and (min-width: 992px) and (max-width: 4000px) {
    .carrinho {
        width: 60%;
    }

    .vazio {
        width: 40%;
    }
}