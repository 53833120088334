.footer {
    background-color: #0D4389;
    display: flex;
    flex-direction: column;
    padding: 40px;

}


.redes svg {
    color: white;
    width: 40px;
    height: 40px;
    margin-right: 30px;
}

.footer1 {
    margin-top: 40px;
}

.footer1 h1,
.bandeiras h1 {
    color: white;
    font-family: Poppins;
    font-weight: 600;
    font-size: 30px;
}

.bandeiras {
    margin-top: 30px;
}

.footer1 a {
    text-decoration: none;
}

.footer1 p {
    color: white;
    font-family: Poppins;
    font-weight: 400;
    margin-top: 10px;
}

.bandeiras img {
    margin-top: 20px;
    width: 50%;
}

@media screen and (min-width: 992px) and (max-width: 4000px) {
    .footer {
        flex-direction: row;
        justify-content: space-between;
    }

    .bandeiras img {
        width: 90%;
    }
}