.info {
    margin: 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.info span {
    color: black;
    font-weight: 600;
}

.info h1 {
    text-align: center;
    margin-top: 20px;
    font-family: Poppins;
    color: #0D4389;
    font-weight: 400;
    font-size: 17px;
    border: 1px solid #ccc;
    padding: 10px;
}

.info button {
    margin-top: 40px;
    background-color: red;
    padding: 10px;
    color: white;
    font-size: 16px;
    font-family: Poppins;
    border: 0;
    border-radius: 10px;
    font-weight: 600;
    width: 50%;
    cursor: pointer;
}