.unlog {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    align-items: center;
}

.unlog h1 {
    font-size: 20px;
    font-family: Poppins;
    text-align: center;
    color: #282828;
    font-weight: 500;
}

.unlog a {
    background-color: #0D4389;
    margin-top: 30px;
    padding: 10px;
    color: white;
    text-align: center;
    width: 50%;
    font-size: 17px;
    font-family: Poppins;
    text-decoration: none;
    font-weight: 600;
    border-radius: 10px;
}

.card1 h1 {
    color: #0d4389;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    text-transform: lowercase;
    text-align: center;
}

.card1 h2 {
    font-size: 12px;
    font-family: Poppins;
    font-weight: 400;
    color: #0d4389;
    text-transform: capitalize;
}

.card1 p {
    color: #000000;
    font-size: 16px;
    font-family: Poppins;
    margin-top: 15px;
    text-align: center;
    font-weight: 500;
}

.card1 img {
    object-fit: cover;
    width: 100%;
    height: 250px;
}

.card1 button {
    background-color: #0d4389;
    margin-top: 15px;
    border: 0;
    width: 100%;
    padding: 5px;
    color: white;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
}

.card {
    display: flex;
    justify-content: center;
    margin: 30px 0px;
    flex-wrap: wrap;
}

.card1 {
    width: 45%;
    margin: 10px 5px;
    cursor: pointer;
}

.empty {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    align-items: center;
}

.empty h1 {
    font-size: 20px;
    font-family: Poppins;
    text-align: center;
}

.empty a {
    background-color: #0D4389;
    margin-top: 30px;
    padding: 10px;
    color: white;
    text-align: center;
    width: 50%;
    font-size: 17px;
    font-family: Poppins;
    text-decoration: none;
    font-weight: 600;
    border-radius: 10px;
}

@media screen and (min-width:992px) and (max-width: 4000px) {
    .card1 {
        width: 20%;
    }

    .card1 img {
        height: 300px;
    }
}