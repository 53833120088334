.popularTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popularTitle h1 {
    font-family: Poppins;
    font-weight: 400;
    color: #0d4389;
    font-size: 28px;
}

.popularTitle p {
    font-size: 14px;
    color: #666666;
    font-family: Poppins;
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
}

.popular1 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    margin: 20px 0px;

}

.popular2 {
    width: 40%;
    margin: 20px 0px;
    background-color: white;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);


}

a.popular2 {
    text-decoration: none;
}

.popular2 span {
    color: #666666;
    font-family: Poppins;
    font-weight: 500;
    font-size: 13px;
    text-decoration: none;
    text-transform: lowercase;
    text-align: center;
    width: 80%;
}

@media screen and (min-width: 992px) and (max-width: 4000px) {
    .popular1 {
        justify-content: space-between;
    }

    .popularTitle h1 {
        font-size: 32px;
    }

    .popularTitle p {
        font-size: 20px;
    }

    .popular2 {
        width: 20%;
        margin: 30px 30px;

    }

    .popular2 a {
        font-size: 20px;
    }
}