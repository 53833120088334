.checkout {
    width: 85%;
    background-color: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;


    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-top: 10px;
    padding-bottom: 30px;
}

.checkout1 {
    width: 85%;
    margin: auto;
    margin-top: 30px;
}

.checkout1 h1 {
    font-family: Poppins;
    font-size: 18px;
    color: #333333;
}

.checkout1 p {
    color: #666666;
    font-size: 14px;
    font-family: Poppins;
}

.checkout1 input {
    padding: 0.5rem;
    width: 85%;
    margin-top: 10px;
    background-color: transparent;
    border: 1px solid #c2c2c2;
    border-radius: 8px;
    outline: none;
    font-size: 1rem;
    font-family: Poppins;
}

.checkout1:last-child input {
    font-size: 16px;
    color: white;
    background-color: #0D4389;
    height: 44px;
    border: 0;
    border-radius: 8px;
    width: 80%;
    font-family: Poppins;
    font-weight: 600;
    cursor: pointer;
}

@media screen and (min-width: 992px) and (max-width: 4000px) {
    .checkout {
        width: 40%;
    }
}