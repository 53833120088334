.card1 {
    width: 45%;
    margin: 10px 5px;
    cursor: pointer;
}

.card1 h1 {
    color: #0d4389;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    text-transform: lowercase;
    text-align: center;
}

.card1 img {
    object-fit: cover;
    width: 100%;
    height: 250px;
}


.card1 p {
    color: #000000;
    font-size: 16px;
    font-family: Poppins;
    margin-top: 15px;
    text-align: center;
    font-weight: 500;
}

.card1 button {
    background-color: #0d4389;
    margin-top: 15px;
    border: 0;
    width: 100%;
    padding: 5px;
    color: white;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
}

@media screen and (min-width:992px) and (max-width: 4000px) {
    .card1 {
        width: 20%;
    }

    .card1 img {
        height: 300px;
    }
}