.add {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: Poppins;
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add1 {
    margin-top: 20px;
    width: 90%;
}

.add1 input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 10px;
    outline: none;
    font-family: Poppins;
    font-size: 14px;
}

.add1 select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 10px;
    outline: none;
    font-family: Poppins;
    font-size: 14px;
    cursor: pointer;
}

.add1 button {
    margin-top: 10px;
    width: 100%;
    height: 30px;
    background-color: #0D4389;
    color: white;
    border: 0;
    border-radius: 5px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.add1 img {
    width: 100%;
    height: 400px;
}

@media screen and (min-width: 992px) and (max-width: 4000px) {
    .add {
        width: 40%;
    }
}