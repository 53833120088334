.inst {
    width: 80%;
    margin: 30px;
}

.inst h1 {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    color: #0D4389;
}

.inst p {
    font-size: 15px;
    font-family: Poppins;
    color: rgb(88, 88, 88);
    margin-top: 15px;
}

@media screen and (min-width: 992px) and (max-width: 4000px) {
    .inst {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 60px 0px;
    }

    .inst h1,
    .inst p {
        width: 60%;
    }
}