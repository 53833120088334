.tr {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0px;
}

.tr1 {
    width: 90%;
    padding: 20px 0px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    background-color: white;
    margin: 10px 0px;
    border-radius: 5px;
}

.tr1 h1,
.tr1 h2,
.tr1 h3 {
    font-family: Poppins;
    color: #333333;
    margin-top: 7px;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 20px;
}

.tr1 span {
    background-color: yellow;
    font-weight: 700;
}

.tr1 h1 {
    font-size: 25px;
}

.tr1 h2 {
    font-size: 13px;
}

.tr1 h3 {
    font-size: 13px;
}

.tr1 p {
    margin-top: 7px;
    cursor: pointer;
    color: #0D4389;
    font-size: 15px;
    font-family: Poppins;
    text-decoration: underline;
    margin-left: 20px;
}

@media screen and (min-width:992px) and (max-width: 4000px) {
    .tr1 {
        width: 40%;
    }

    .tr1 h2 {
        font-size: 20px;
    }

    .tr1 h3 {
        font-size: 15px;
    }
}