.sliderProduct {
  margin: 10px 0px;
  margin-bottom: 15px;
  border-bottom: 1px solid #cccccc;
}

.sliderProductTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sliderProductTitle h1 {
  font-family: Poppins;
  font-weight: 400;
  color: #0d4389;
  font-size: 28px;
}

.sliderProductTitle p {
  font-size: 14px;
  color: #666666;
  font-family: Poppins;
  font-weight: 500;
  margin-top: 10px;
}

.card1 {
  margin: 30px 0px;
  cursor: pointer;
}


.card1 h1 {
  color: #0d4389;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  text-transform: lowercase;
}

.card1 img {
  object-fit: cover;
  width: 100%;
  height: 250px;
}

.card1 h2 {
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  color: #0d4389;
  text-transform: capitalize;
}

.card1 p {
  color: #000000;
  font-size: 16px;
  font-family: Poppins;
  margin-top: 15px;
  text-align: center;
  font-weight: 500;
}

.card1 button {
  background-color: #0d4389;
  margin-top: 15px;
  border: 0;
  width: 100%;
  padding: 5px;
  color: white;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
}

.place {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;

}

.place img {
  width: 45%;
  height: 300px;
  margin: 0px 10px;
  border-radius: 10px;
}



.placeD {
  display: none;
}

@media screen and (min-width: 992px) and (max-width: 4000px) {

  .sliderProductTitle h1 {
    font-size: 32px;
  }

  .sliderProductTitle p {
    font-size: 20px;
  }

  .card1 img {
    height: 300px;
  }

  .placeD {
    display: flex;
    justify-content: center;
    margin: 30px 0px;
    width: 100%;
  }

  .placeD img {
    width: 17%;
    height: 300px;
    margin: 0px 15px;
  }

  .place {
    display: none;
  }
}