.card {
    display: flex;
    justify-content: center;
    margin: 30px 0px;
    flex-wrap: wrap;
    width: 100%;
}

.card1 {
    width: 40%;
    margin: 10px 10px;

}



.card1 img {
    object-fit: cover;
    width: 100%;
    height: 250px;
}

@media screen and (min-width:992px) and (max-width: 4000px) {
    .card1 {
        width: 20%;
    }

    .card1 img {
        height: 300px;
    }
}